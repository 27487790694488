<template>
  <div>
    <app-main>
      <template v-slot:main-top>
        <!-- 查询 -->
        <div class="query-header">
          <el-form label-width="80px" :model="form">
            <el-row>
              <el-col :span="6">
                <el-form-item label="订单号">
                  <el-input v-model="form.order" class="input" placeholder="订单号"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="用户ID">
                  <el-input v-model="form.id" class="input" placeholder="用户ID"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="备注">
                  <el-input v-model="form.msg" class="input" placeholder="备注信息"></el-input>
                </el-form-item>
                <el-form-item label="查询时间">
                  <el-date-picker class="input" @change="dateChange" v-model="form.times" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <div class="date-filter">
                    <span :class="{'active': spanIndex === index}" @click="dateClick(index)" v-for="(item, index) in dateSelection" :key="index">{{item}}</span>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="small" @click="queryClick">查询</el-button>
                  <el-button size="small" @click="resetClick">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
      <template v-slot:main-content>
        <div class="content">
          <!-- 表格 -->
          <div class="menu">
            <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="selectChange">
              <el-menu-item index="0">全部</el-menu-item>
              <el-menu-item index="1">待支付</el-menu-item>
              <el-menu-item index="2">打单中</el-menu-item>
              <el-menu-item index="3">已出单</el-menu-item>
              <el-menu-item index="4">异常</el-menu-item>
              <el-menu-item index="5">取消</el-menu-item>
            </el-menu>
          </div>
          <div class="table">
            <el-table :data="tableData.data" style="width: 100%" :header-cell-style="{background:'#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500'}" :row-style="{height: '78px'}">
              <el-table-column label="序号" width="70">
                <template slot-scope="scope"><span>{{scope.$index+(currentPage - 1) * pageSize + 1}}</span></template>
              </el-table-column>
              <el-table-column label="订单号" prop="order_no">
              </el-table-column>
              <el-table-column label="商品信息" prop="goods_name">
              </el-table-column>
              <el-table-column prop="package_num" label="包裹数">
              </el-table-column>
              <!-- <el-table-column prop="order_reward" label="邀请奖励"> </el-table-column> -->
              <el-table-column prop="order_status" label="订单状态">
                <template slot-scope="scope">
                  <!-- 待支付 -->
                  <div v-if="scope.row.status === '1'" class="to-be-paid state">
                    <span></span>待支付
                  </div>
                  <!-- 打单中 -->
                  <div v-if="scope.row.status === '2'" class="in-order state">
                    <span></span>打单中
                  </div>
                  <!-- 已出单 -->
                  <div v-if="scope.row.status === '3'" class="order-issued state">
                    <span></span>已出单
                  </div>
                  <!-- 异常 -->
                  <div v-if="scope.row.status === '4'" class="abnormal state">
                    <span></span>异常
                  </div>
                  <!-- 取消 -->
                  <div v-if="scope.row.status === '5'" class="cancel state">
                    <span></span>取消
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="user_id" label="用户ID">
              </el-table-column>
              <el-table-column prop="order_tips" label="备注">
              </el-table-column>
              <el-table-column label="更新时间">
                <template slot-scope="scope"><span>{{scope.row.updatetime}}</span></template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination background @current-change="currentChange" :page-size="tableData.per_page" layout="prev, pager, next" :current-page="tableData.current_page" :total="tableData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { rewardSelect } from 'network/api'
import { timeConversion } from 'utils/utils'

export default {
  components: {
    AppMain
  },
  inject: ['mainReload'],
  data() {
    return {
      options: ['淘宝/天猫', '阿里巴巴', '拼多多', '京东', '抖音', '其他'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        order: '', // 单号
        timeValue: '', // 选择时间
        times: [],
        msg: '', // 备注
        id: '' // 用户id
      },
      spanIndex: 0,
      activeIndex: '0',
      tableData: {}, // 每次展示的数据
      currentPage: 1, // 当前页
      pageSize: 15 // 每页数据
    }
  },
  computed: {
    timeConversion() {
      return timeConversion
    }
  },
  created() {
    // 初始化时间选择器
    const dayStar = new Date()
    const dayEnd = new Date()
    dayStar.setTime(dayStar.getTime())
    dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
    // 初始化全部商品
    this.getTableData()
  },
  methods: {
    /* 筛选请求 */
    getTableData() {
      rewardSelect({
        order_status: this.activeIndex,
        order_no: this.form.order,
        createtime: this.form.times,
        order_tips: this.form.msg,
        page: this.currentPage
      }).then(res => {
        // console.log(res)
        if (res.code === 1) {
          this.tableData = res
        }
      })
    },
    selectChange(val) {
      this.activeIndex = val
      this.getTableData()
    },
    currentChange(val) {
      this.currentPage = val
      this.getTableData()
    },
    // 日期改变
    dateChange(val) {
      this.form.times = val
      this.getTableData()
    },
    // 查询
    queryClick() {
      this.getTableData()
    },
    // 点击日期
    dateClick(index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getTableData()
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getTableData()
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getTableData()
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getTableData()
          break
      }
    },
    // 重置
    resetClick() {
      this.mainReload()
    }
  }
}
</script>
<style scoped>
.input[data-v-16a40a0a] {
  width: 80% !important;
}
.el-col-6 {
    width: 30rem;
}
</style>
<style scoped src="styles/components/queryHeader.css">
</style>

<style scoped src="styles/views/account/inviteRewards.css">
</style>
